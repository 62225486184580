html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.swiper-pagination-bullet {
  width: 40px !important;
  height: 5px !important;
  background-color: #a3a3a3;
  border-radius: 0;
  cursor: pointer !important;
}

.swiper-container {
  padding-bottom: 50px;
}

.swiper-pagination-bullet-active {
  background-image: linear-gradient(45deg, #dc1fff 15%, #00ffa3 65%) !important;
  opacity: 1 !important;
}

.timeline::before {
  width: 6px;
}

.heading {
  background: #dc1fff;
  background: -webkit-linear-gradient(to right, #dc1fff 0%, #00ffa3 100%);
  background: -moz-linear-gradient(to right, #dc1fff 0%, #00ffa3 100%);
  background: linear-gradient(to right, #dc1fff 0%, #00ffa3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-box {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.team-box:hover {
  transform: translateY(-1%);
  background-image: linear-gradient(to bottom right, #dc1fff 0%, #00ffa3 100%);
}

.icon-wrapper {
  display: none;
}

.row-title-text {
  font-size: 24px;
  text-align: center;
  width: 100%;
  padding-right: 0 !important;
}

.row-content-text {
  font-size: 22px;
  text-align: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 10px 20px;
  transition: all 0.5s ease;
}

.header.is-sticky {
  background: #1111119e;
}

.header .link {
  transition: all 0.5s ease;
  text-decoration: none !important;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 15px;
  outline: none;
}

.link {
  transition: all 0.5s ease;
  text-decoration: none !important;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 15px;
  outline: none;
}

.header .link:last-child {
  margin-right: 0;
}

.header .link:hover {
  color: #00ffa3;
}

.header .logo {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  font-family: 'Khula', sans-serif;
  margin: 0;
  line-height: normal;
  text-decoration: none !important;
}

.header .logo:hover {
  color: #00ffa3;
}

@media screen and (max-width: 1169px) {
  .vertical-timeline-element-icon {
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 768px) {
  .header {
    background: #1111119e;
  }
}

.address {
  color: #fff;
}

.goal-percent span {
  font-size: 24px !important;
}

.nftmodal {
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
